import {
  BrandName,
  eLaunchNowCustomerSupportEmail,
  eLaunchNowPrivacyPolicyUrl,
  eLaunchNowTermsAndConditionsUrl,
  EnvironmentParameters
} from '@libs/shared/bms-common/environment/environment.model';

export const environment: EnvironmentParameters = {
  googleTrackingId: 'G-YQYVF20EPX',
  production: false,
  environmentEndpoint: '/api/environment',
  usaApiUrl: 'https://api.demo.app.elaunchnow.com',
  europeApiUrl: 'https://api.demo.app.staffnow.aero',
  auth: {
    initialUrl: 'https://backoffice.demo.staffnow.aero',
    jwtIssuer: 'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo',
    keycloakLoginUrl:
      'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo/protocol/openid-connect/token',
    clientId: 'staffnow-demo-login'
  },
  brandConfig: {
    brandName: BrandName.eLAUNCHNow,
    privacyPolicy: eLaunchNowPrivacyPolicyUrl(),
    termsAndConditions: eLaunchNowTermsAndConditionsUrl(),
    customerSupportEmail: eLaunchNowCustomerSupportEmail(),
    secondCustomerSupportEmail: eLaunchNowCustomerSupportEmail(),
    infoEmail: eLaunchNowCustomerSupportEmail(),
    appUrl: 'https://demo.app.elaunchnow.com/',
    partnerAppUrl: 'https://demo.staffnow.aero/'
  },
  euDashboardsEndpoint:
    'https://2bwibmse1e.execute-api.eu-west-1.amazonaws.com/default/reports/',
  usaDashboardsEndpoint:
    'https://p0840gxrni.execute-api.us-east-1.amazonaws.com/default/reports/',
  privacyPolicyUrl: 'https://www.launchtws.com/privacy-policy/',
  termsAndConditionsUrl: 'https://www.launchtws.com/terms-of-use/',
  techniciansContactUrl: 'https://staffnow.aero/#contact-for-technicians',
  companiesContactUrl: 'https://staffnow.aero/contact'
};
